import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/sidebar';
import Header from './Header';
import { FaCogs, FaCreditCard, FaHome, FaIdCard, FaPhone, FaUser } from 'react-icons/fa';
import Pagination from 'react-bootstrap/Pagination';
import { CiSearch } from 'react-icons/ci';
import { BiRefresh } from 'react-icons/bi';

const Crm = () => {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [data, setData] = useState({ sale: [] });
  const [activeTable, setActiveTable] = useState('sales'); // Default active table
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  useEffect(() => {
    fetchSaleData();
  }, []);

  const fetchSaleData = async () => {
    const userId = JSON.parse(sessionStorage.getItem('userData')).id;
    try {
      const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/leads?userId=${encodeURIComponent(userId)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fetchedData = await response.json();
      const filteredData = fetchedData.filter(item => item.interestTab === 1);
      setData({ sale: filteredData });
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  const handleSend = () => {

  };

  const handlecancellation = () => {

  };

  const totalPages = Math.ceil(data.sale.length / itemsPerPage);


  const getCurrentData = () => {
    return data.sale.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  };

  const handleTableChange = (table) => {
    setActiveTable(table);
    setCurrentPage(1);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const [searchQuery, setSearchQuery] = useState('');
  const handleSearch = () => {
    const filteredItem = data.sale.filter((item) =>
      item.customername.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.aadhar_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.pan_number.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.permanent_address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.mobile.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setData({ sale: filteredItem });
  };

  return (
    <>
      <div className="grid-container">
        <Header OpenSidebar={OpenSidebar} />
        <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

        <div className="main-container" style={{ backgroundColor: '#e2eef1' }}>
          <div style={{ marginTop: '10px' }}>
            <h5 className='mb-1'>CUSTOMERS DETAILS OVERVIEW</h5>
            <p>Organize Customers and track their progress effectively here</p>
            <div className='mb-2'>
              <button onClick={() => handleTableChange('sales')} style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px' }}>
                Sale Booking Platform
              </button>
              <button onClick={() => handleTableChange('database')} style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px' }}>
                Database Platform
              </button>
              <button onClick={() => handleTableChange('document')} style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px' }}>
                Document Platform
              </button>
              <button onClick={() => handleTableChange('cancellation')} style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px' }}>
                Cancellation Platform
              </button>
            </div>
          </div>

          {activeTable === 'sales' && (
            <>
              <h5 className='text-center mb-2'>INTERESTED SALE BOOKING PLATFORM CUSTOMERS</h5>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ marginLeft: '10px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} />
                  <CiSearch style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handleSearch} />
                </div>
                <div><button onClick={handleRefresh}><BiRefresh /></button></div>
              </div>
              <h6 className='mt-3'>Total Customers : {data.sale.length} </h6>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th><FaUser />Name</th>
                    <th><FaPhone />Mobile</th>
                    <th><FaIdCard />Aadhar</th>
                    <th><FaCreditCard />Pan</th>
                    <th><FaHome />Address</th>
                    <th><FaCogs />Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentData().length > 0 ? (
                    getCurrentData().map((row, index) => (
                      <tr key={index}>
                        <td>{row.customername}</td>
                        <td>{row.mobile}</td>
                        <td>{row.aadhar_number}</td>
                        <td>{row.pan_number}</td>
                        <td>{row.permanent_address}</td>
                        <td><button onClick={() => handleSend(index)} className="btn btn-primary btn-sm">Send</button></td>
                      </tr>
                    ))
                  ) : (
                    <tr><td colSpan="6">No data available</td></tr>
                  )}
                </tbody>
              </table>
              <Pagination className="justify-content-center mt-4">
                <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                {[...Array(totalPages).keys()].map(page => (<Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>{page + 1}</Pagination.Item>))}
                <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
              </Pagination>
            </>
          )}

          {activeTable === 'cancellation' && (
            <>
              <h5 className='text-center mb-2'>CANCELLATION PLATFORM</h5>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ marginLeft: '10px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} />
                  <CiSearch style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handleSearch} />
                </div>
                <div><button onClick={handleRefresh}><BiRefresh /></button></div>
              </div>
              <table className="table table-bordered" style={{ marginTop: '20px' }}>
                <thead>
                  <tr>
                    <th><FaUser />Name</th>
                    <th><FaPhone />Mobile</th>
                    <th><FaIdCard />Aadhar</th>
                    <th><FaCreditCard />Pan</th>
                    <th><FaHome />Address</th>
                    <th><FaCogs />Action</th>
                  </tr>
                </thead>
                <tbody>
                  {getCurrentData().length > 0 ? (
                    getCurrentData().map((row, index) => (
                      <tr key={index}>
                        <td>{row.customername}</td>
                        <td>{row.mobile}</td>
                        <td>{row.aadhar_number}</td>
                        <td>{row.pan_number}</td>
                        <td>{row.permanent_address}</td>
                        <td><button onClick={() => handlecancellation(index)} className="btn btn-danger btn-sm">cancellation</button></td>
                      </tr>
                    ))
                  ) : (
                    <tr><td colSpan="6">No data available</td></tr>
                  )}
                </tbody>
              </table>
              <Pagination className="justify-content-center mt-4">
                <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
                {[...Array(totalPages).keys()].map(page => (<Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => setCurrentPage(page + 1)}>{page + 1}</Pagination.Item>))}
                <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
              </Pagination>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Crm;
