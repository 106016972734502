import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Button, Alert } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { AiOutlineLock } from 'react-icons/ai';




const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch(`https://cloudconnectapi.emedha.in/auth/api/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Password reset successfully.');
        setError('');
        setNewPassword('');
        setConfirmPassword('');
        setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
      } else {
        setError(data.error || 'Failed to reset password');
        setSuccess('');
      }
    } catch (error) {
      console.error('Reset password error:', error);
      setError('Failed to reset password');
      setSuccess('');
    }
  };

  return (
    <>

      <div className="d-flex justify-content-center align-items-center min-vh-60 " >

          <Col className=" align-items-center p-5" >
            <h1 className='text-center mt-5' style={{ fontSize: '50px' }}><strong>Reset Password</strong></h1>
            <form onSubmit={handleResetPassword}>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <div className='mb-3' style={{ position: 'relative' }}>
              <AiOutlineLock style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
                <input type="password" placeholder="Enter new password" style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
              </div>
              <div className='mb-3' style={{ position: 'relative' }}>
              <AiOutlineLock style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
                <input className='mb-3' type="password" style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} placeholder="Confirm new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
              </div>

              <ReCAPTCHA className='mb-3' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />

              <button type="submit" variant="danger btn-sm" style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%', backgroundColor: '#07526f', color: 'white' }} className="mt-3">
                Save New Password
              </button>
            </form>
          </Col>

      </div>

    </>
  );
};

export default ResetPassword;
