import React, { useState } from 'react';
import { AiOutlineLock, AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import A1 from '../Images/elo.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://cloudconnectapi.emedha.in/auth/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        sessionStorage.setItem('userData', JSON.stringify({ email, id: data.user.id }));
        sessionStorage.setItem('userId', data.user.id);
        sessionStorage.setItem('emailUser', email);
        if (data.user.company_logo) {
          sessionStorage.setItem('companyLogo', data.user.company_logo);
        }
        alert('Login successful!');
        navigate('/dashboard');
      } else {
        alert(data.error || 'Invalid credentials. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed. Please try again later.');
    }
  };

  const handleSubmit = (e) => {
    handleLogin(e);
  };

  return (
    <div className='d-flex justify-content-center mt-5' >
      <div className='col-lg-4 col-md-6 col-sm-8'>
        <img src={A1} alt='nir' style={{ height: '40px' }} />
        <h1 className='text-center mt-3' style={{ fontSize: '50px' }}><strong>Login</strong></h1>
        <form className='mt-5' onSubmit={handleSubmit}>
          <div className='mb-3' style={{ position: 'relative' }}>
            <AiOutlineMail style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
            <input type='email' placeholder='Email' style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} value={email} onChange={handleEmailChange} required />
          </div>
          <div className='mb-3' style={{ position: 'relative' }}>
            <AiOutlineLock style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
            <input type='password' placeholder='Password' style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} value={password} onChange={handlePasswordChange} required />
          </div>

          <ReCAPTCHA className='mb-3' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />

          <div className='gap-2'>
            <button type='submit' className='btn btn-dark' style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%', backgroundColor: '#07526f', color: 'white' }}>LOGIN</button>
          </div>
        </form>

        <div className='mt-3' style={{ textAlign: 'center' }}>
          <hr style={{ width: '100px', display: 'inline-block' }} />
          <span>or</span>
          <hr style={{ width: '100px', display: 'inline-block' }} />
        </div>

        <div className='d-flex justify-content-between mt-3'>
          <Link to='/forgot' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <AiOutlineLock style={{ marginRight: '5px' }} />
            Forgot your Password?
          </Link>
          or I'm new here,
          <Link to='/signup' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
            <AiOutlineUser style={{ marginRight: '5px' }} />
            Create an account
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
