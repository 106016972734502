import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../Sidebar/sidebar';
import Header from './Header';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { FaArrowDown, FaArrowUp, FaBaby, FaCalendarAlt, FaCog, FaComment, FaEnvelope, FaMobileAlt, FaShirtsinbulk, FaTransgender, FaUser } from 'react-icons/fa';
import { FaEllipsisVertical } from 'react-icons/fa6';
import { BiRefresh } from 'react-icons/bi';
import { CiSearch } from 'react-icons/ci';
import axios from 'axios';
import { MdDelete } from "react-icons/md";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';


export default function Primary() {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [leads, setLeads] = useState([]);
    const [showCallbackModal, setShowCallbackModal] = useState(false);
    const [callbackDate, setCallbackDate] = useState('');
    const [callbackLeadId, setCallbackLeadId] = useState(null);
    const [showNotInterestedModal, setShowNotInterestedModal] = useState(false);
    const [notInterestedReason, setNotInterestedReason] = useState('');
    const [notInterestedLeadId, setNotInterestedLeadId] = useState(null);


    const [formData, setFormData] = useState({
        customername: '',
        gender: '',
        location: '',
        createdDate: '',
        email: '',
        mobile: '',
        requirement: '',
    });
    const [activeTable, setActiveTable] = useState('leads');


    const fetchLeads = async () => {
        const userId = JSON.parse(sessionStorage.getItem('userData')).id;
        try {
            const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/leads?userId=${encodeURIComponent(userId)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setLeads(data);
        } catch (error) {
            console.error('Error fetching leads:', error);
        }
    };

    useEffect(() => {
        fetchLeads();
    }, []);

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const createdAt = new Date().toISOString();
        const userId = JSON.parse(sessionStorage.getItem('userData')).id;
        const newLead = { ...formData, createdDate: createdAt, userId };

        try {
            const response = await fetch('https://cloudconnectapi.emedha.in/pages/api/api/leads', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newLead),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            fetchLeads();
            setLeads((prevLeads) => [...prevLeads, { ...newLead, id: data.id }]);
            setFormData({
                customername: '',
                gender: '',
                location: '',
                createdDate: '',
                email: '',
                mobile: '',
                requirement: '',
            });
            setShowModal(false);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };



    const handleRefresh = () => {
        window.location.reload();
    };

    const handleTableSwitch = (tableName) => {
        setActiveTable(tableName);
    };


    const [dropdownIndex, setDropdownIndex] = useState(null);
    const dropdownRef = useRef(null);

    const handleToggleDropdown = (index) => {
        setDropdownIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [comment, setComment] = useState('');
    const [id, setId] = useState()


    const handleActionClick = (action, id) => {
        if (action === 'Interested') {
            setIsModalOpen(true);
            setId(id);
        } else if (action === 'Callback') {
            setCallbackLeadId(id);
            setShowCallbackModal(true);
        } else if (action === 'Not Interested') {
            setNotInterestedLeadId(id);
            setShowNotInterestedModal(true);
        } else {
            console.log(action, id);
        }
        setDropdownIndex(null);
    };

    const [selectedCustomer, setSelectedCustomer] = useState('');
    const selectedLead = leads.find(lead => lead.customername === selectedCustomer);
    const [alternateMobile, setAlternateMobile] = useState('');
    const [aadharNumber, setAadharNumber] = useState('');
    const [panNumber, setPanNumber] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');


    const handleinterested = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/comment/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({
                    comment,
                    alternate_mobile: alternateMobile,
                    aadhar_number: aadharNumber,
                    pan_number: panNumber,
                    permanent_address: permanentAddress,
                    current_address: currentAddress,
                }),
            });
            if (response.ok) {
                console.log('Lead updated successfully');
                fetchLeads();
                setLeads((prevLeads) => {
                    const updatedLeads = prevLeads.map((lead) => {
                        if (lead.id === id) {
                            return {
                                ...lead,
                                interestcomment: comment,
                                alternate_mobile: alternateMobile,
                                aadhar_number: aadharNumber,
                                pan_number: panNumber,
                                permanent_address: permanentAddress,
                                current_address: currentAddress,
                            };
                        }
                        return lead;
                    });
                    return updatedLeads;
                });
            } else {
                const errorMessage = await response.text();
                console.error('Error updating lead:', errorMessage);
            }
        } catch (error) {
            console.error('Network error:', error);
        }

        setIsModalOpen(false);
        setComment('');
        setAlternateMobile('');
        setAadharNumber('');
        setPanNumber('');
        setPermanentAddress('');
        setCurrentAddress('');
    };

    const handleCallbackSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`https://cloudconnectapi.emedha.in/pages/api/api/callback/${callbackLeadId}`, { callbackdate: callbackDate });

            if (response.status === 200) {
                console.log('Callback date updated successfully');
                fetchLeads();

                setLeads((prevLeads) => {
                    return prevLeads.map((lead) => {
                        if (lead.id === callbackLeadId) {
                            return {
                                ...lead,
                                callbackdate: callbackDate,
                                notinterestTab: 0,
                            };
                        }
                        return lead;
                    });
                });

                setShowCallbackModal(false);
                setCallbackDate('');
            } else {
                const errorMessage = await response.text();
                console.error('Error updating callback:', errorMessage);
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };

    const handleNotInterestedSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/reason/${notInterestedLeadId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ reason: notInterestedReason }),
            });

            if (response.ok) {
                fetchLeads();
                console.log('Lead marked as not interested successfully');

                // Update the local state immediately
                setLeads((prevLeads) => {
                    return prevLeads.map((lead) => {
                        if (lead.id === notInterestedLeadId) {
                            return {
                                ...lead,
                                reason: notInterestedReason, // Store the reason directly
                                notinterestTab: 1, // Mark as not interested
                            };
                        }
                        return lead;
                    });
                });
            } else {
                const errorMessage = await response.text();
                console.error('Error updating lead:', errorMessage);
            }
        } catch (error) {
            console.error('Network error:', error);
        }

        setShowNotInterestedModal(false);
        setNotInterestedReason(''); // Clear the reason field
    };


    const exportToExcel = (leads) => {
        const worksheet = XLSX.utils.json_to_sheet(leads);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');
        XLSX.writeFile(workbook, 'leads.xlsx');
    };

    const exportToPDF = (leads) => {
        const doc = new jsPDF();
        const tableColumn = ["Customer", "Gender", "Location", "Email", "Mobile", "Requirement", "Created Date"];
        const tableRows = [];

        leads.forEach(lead => {
            const leadData = [
                lead.customername,
                lead.gender,
                lead.location,
                lead.email,
                lead.mobile,
                lead.requirement,
                new Date(lead.createdDate).toLocaleDateString(),
            ];
            tableRows.push(leadData);
        });

        doc.autoTable(tableColumn, tableRows);
        doc.save('leads.pdf');
    };

    const handleDeleteClick = async (leadId) => {
        try {
            const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/delete/${leadId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                const errorData = await response.json();
                alert(errorData.message || 'Failed to delete the lead');
            } else {
                setLeads((prevLeads) => prevLeads.filter(lead => lead.id !== leadId));
                alert('Lead deleted successfully');
            }
        } catch (error) {
            console.error('Error deleting lead:', error);
            alert('Error deleting lead');
        }
    };

    const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleBulkUpload = async () => {
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        const userId = JSON.parse(sessionStorage.getItem('userData')).id; // Get user email from sessionStorage
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId); // Append userId to FormData

        try {
            const response = await fetch('https://cloudconnectapi.emedha.in/pages/api/api/bulkupload', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                alert(errorData.message || 'Upload failed');
                return;
            }

            const data = await response.json();
            alert(data.message);
            setShowBulkUploadModal(false);
            fetchLeads();
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading file');
        }
    };


    const [searchQuery, setSearchQuery] = useState('');
    const handleSearch = () => {
        const filteredLeads = leads.filter(lead =>
            lead.customername.toLowerCase().includes(searchQuery.toLowerCase()) ||
            lead.gender.toLowerCase().includes(searchQuery.toLowerCase()) ||
            lead.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
            lead.createdDate.toLowerCase().includes(searchQuery.toLowerCase()) ||
            lead.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            lead.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
            lead.requirement.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setLeads(filteredLeads);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const packagesPerPage = 10;

    const filteredLeads = leads.filter(
        lead => lead.callbackTab === 0 && lead.notinterestTab === 0 && lead.interestTab === 0
    );

    const totalPages = Math.ceil(filteredLeads.length / packagesPerPage);
    const indexOfLastLead = currentPage * packagesPerPage;
    const indexOfFirstLead = indexOfLastLead - packagesPerPage;
    const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

    const company_logo = sessionStorage.getItem("company_logo");

    return (
        <>
            <div className='grid-container'>
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

                <div className='main-container' style={{ backgroundColor: '#e2eef1' }}>
                    <div>
                        <div className='mt-3 d-flex justify-content-between align-items-center'>
                            <h5>LEAD DETAILS</h5>
                            <div>
                                <Button variant='primary btn-sm me-2' onClick={() => setShowModal(true)}> + New Lead</Button>
                                <Button variant='secondary btn-sm me-2' onClick={() => exportToExcel(leads)}><FaArrowDown /> Export to Excel</Button>
                                <Button variant='secondary btn-sm' onClick={() => exportToPDF(leads)}><FaArrowDown /> Export to PDF</Button>
                            </div>
                        </div>
                        <p>Organize leads and track their progress effectively here</p>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ marginLeft: '10px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }} />
                                <CiSearch style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handleSearch} />
                            </div>
                            <div>
                                <button style={{ marginRight: '10px' }} onClick={() => setShowBulkUploadModal(true)}><FaShirtsinbulk /> Bulk Action</button>
                                <button onClick={handleRefresh}><BiRefresh /></button>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <thead>
                                <tr>
                                    <th style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px', }} onClick={() => handleTableSwitch('leads')}>Lead</th>
                                    <th style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px', }} onClick={() => handleTableSwitch('interested')}>Interested</th>
                                    <th style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px', }} onClick={() => handleTableSwitch('callback')}>Call Back</th>
                                    <th style={{ padding: '8px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center', cursor: 'pointer', borderRadius: '10px', }} onClick={() => handleTableSwitch('notInterested')}>Not Interested</th>
                                </tr>
                            </thead>
                        </div>

                        {/* Conditional rendering based on the active table */}
                        {activeTable === 'leads' && (
                            <>
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th><FaUser /> Customer</th>
                                            <th><FaTransgender /> Gender</th>
                                            <th><FaBaby /> Location</th>
                                            <th><FaEnvelope /> Email</th>
                                            <th><FaMobileAlt /> Mobile</th>
                                            <th><FaMobileAlt /> Requirement</th>
                                            <th><FaCalendarAlt /> Created Date <FaArrowUp /></th>
                                            <th><FaCog /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentLeads.map((lead, index) => (
                                            <tr key={lead.id}>
                                                <td>{lead.customername}</td>
                                                <td>{lead.gender}</td>
                                                <td>{lead.location}</td>
                                                <td>{lead.email}</td>
                                                <td>{lead.mobile}</td>
                                                <td>{lead.requirement}</td>
                                                <td>{new Date(lead.createdDate).toLocaleDateString()}</td>
                                                <td>
                                                    <div style={{ position: 'absolute' }} ref={dropdownRef}>
                                                        <MdDelete style={{ cursor: 'pointer' }} onClick={() => handleDeleteClick(lead.id)} />
                                                        <FaEllipsisVertical style={{ cursor: 'pointer' }} onClick={() => handleToggleDropdown(index)} />
                                                        {dropdownIndex === index && (
                                                            <div style={{ position: 'absolute', background: 'white', border: '1px solid #ccc', right: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', zIndex: 1000, padding: '5px', }}>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Interested', lead.id)}>Interested</div>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Callback', lead.id)}>Callback</div>
                                                                <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Not Interested', lead.id)}>NotInterested</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}

                        {/* Placeholder for interested table */}
                        {activeTable === 'interested' && (
                            <>
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th><FaUser /> Customer</th>
                                            <th><FaTransgender /> Gender</th>
                                            <th><FaBaby /> Location</th>
                                            <th><FaEnvelope /> Email</th>
                                            <th><FaMobileAlt /> Mobile</th>
                                            <th><FaMobileAlt /> Requirement</th>
                                            <th><FaComment /> Comment</th>
                                            <th><FaCog /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads
                                            .filter(lead => lead.callbackTab === 0 && lead.notinterestTab === 0 && lead.interestTab === 1)
                                            .map((lead, index) => (
                                                <tr key={lead.id}>
                                                    <td>{lead.customername}</td>
                                                    <td>{lead.gender}</td>
                                                    <td>{lead.location}</td>
                                                    <td>{lead.email}</td>
                                                    <td>{lead.mobile}</td>
                                                    <td>{lead.requirement}</td>
                                                    <td>{lead.interestcomment}</td>
                                                    <td>
                                                        <div style={{ position: 'absolute' }} ref={dropdownRef}>
                                                            <FaEllipsisVertical onClick={() => handleToggleDropdown(index)} />
                                                            {dropdownIndex === index && (
                                                                <div style={{ position: 'absolute', background: 'white', border: '1px solid #ccc', right: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', zIndex: 1000, padding: '5px', }}>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Callback', lead.id)}>Callback</div>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Not Interested', lead.id)}>NotInterested</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </>
                        )}

                        {/*  Placeholder for callback table */}
                        {activeTable === 'callback' && (
                            <>
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th><FaUser /> Customer</th>
                                            <th><FaTransgender /> Gender</th>
                                            <th><FaBaby /> Location</th>
                                            <th><FaEnvelope /> Email</th>
                                            <th><FaMobileAlt /> Mobile</th>
                                            <th><FaMobileAlt /> Requirement</th>
                                            <th><FaCalendarAlt /> CallBack Date <FaArrowUp /></th>
                                            <th><FaCog /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads
                                            .filter(lead => lead.callbackTab === 1 && lead.notinterestTab === 0 && lead.interestTab === 0) // Only show leads with a valid callback date and not marked as interested or not interested
                                            .map((lead, index) => (
                                                <tr key={lead.id}>
                                                    <td>{lead.customername}</td>
                                                    <td>{lead.gender}</td>
                                                    <td>{lead.location}</td>
                                                    <td>{lead.email}</td>
                                                    <td>{lead.mobile}</td>
                                                    <td>{lead.requirement}</td>
                                                    <td>{new Date(lead.callbackdate).toLocaleDateString()}</td>
                                                    <td>
                                                        <div style={{ position: 'absolute' }} ref={dropdownRef}>
                                                            <FaEllipsisVertical onClick={() => handleToggleDropdown(index)} />
                                                            {dropdownIndex === index && (
                                                                <div style={{ position: 'absolute', background: 'white', border: '1px solid #ccc', right: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', zIndex: 1000, padding: '5px', }}>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Interested', lead.id)}>Interested</div>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Not Interested', lead.id)}>NotInterested</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </>
                        )}

                        {/* Placeholder for notinterested table */}
                        {activeTable === 'notInterested' && (
                            <>
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th><FaUser /> Customer</th>
                                            <th><FaTransgender /> Gender</th>
                                            <th><FaBaby /> Location</th>
                                            <th><FaEnvelope /> Email</th>
                                            <th><FaMobileAlt /> Mobile</th>
                                            <th><FaMobileAlt /> Requirement</th>
                                            <th><FaComment /> Comment</th>
                                            <th><FaCog /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leads
                                            .filter(lead => lead.callbackTab === 0 && lead.notinterestTab === 1 && lead.interestTab === 0)
                                            .map((lead, index) => (
                                                <tr key={lead.id}>
                                                    <td>{lead.customername}</td>
                                                    <td>{lead.gender}</td>
                                                    <td>{lead.location}</td>
                                                    <td>{lead.email}</td>
                                                    <td>{lead.mobile}</td>
                                                    <td>{lead.requirement}</td>
                                                    <td>{lead.reason}</td>
                                                    <td>
                                                        <div style={{ position: 'absolute' }} ref={dropdownRef}>
                                                            <FaEllipsisVertical onClick={() => handleToggleDropdown(index)} />
                                                            {dropdownIndex === index && (
                                                                <div style={{ position: 'absolute', background: 'white', border: '1px solid #ccc', right: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', zIndex: 1000, padding: '5px', }}>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Interested', lead.id)}>Interested</div>
                                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleActionClick('Callback', lead.id)}>Callback</div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} style={{ marginRight: '10px', borderRadius: '10px' }} >Previous</button>
                        <span>{currentPage} / {totalPages}</span>
                        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} style={{ marginLeft: '10px', borderRadius: '10px' }}>Next</button>
                    </div>
                </div>
            </div>

            {/* Modal for New Lead */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton><h6>ADD NEW LEAD</h6></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formCustomer"><Form.Label>Customer</Form.Label><input type="text" name="customername" value={formData.customername} onChange={handleChange} style={{ border: '1px solid black', padding: '10px', width: '100%' }} /></Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGender"><Form.Label>Gender</Form.Label>
                                    <select name="gender" value={formData.gender} onChange={handleChange} style={{ border: '1px solid black', padding: '10px', width: '100%' }} required>
                                        <option value="">Select...</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formRequirement"><Form.Label>Requirement</Form.Label><input type="text" name="requirement" value={formData.requirement} onChange={handleChange} style={{ border: '1px solid black', padding: '10px', width: '100%' }} /></Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formLactation"><Form.Label>Location</Form.Label><input type="text" name="location" value={formData.location} onChange={handleChange} style={{ border: '1px solid black', padding: '10px', width: '100%' }} /></Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formCreatedDate"><Form.Label>Created Date</Form.Label><input type="date" name="createdDate" value={formData.createdDate} onChange={handleChange} style={{ border: '1px solid black', padding: '10px', width: '100%' }} min={new Date().toISOString().split("T")[0]} required /></Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEmail"><Form.Label>Email</Form.Label><input type="email" name="email" value={formData.email} onChange={handleChange} style={{ border: '1px solid black', padding: '10px', width: '100%' }} /></Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formMobile"><Form.Label>Mobile</Form.Label><input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} style={{ border: '1px solid black', padding: '10px', width: '100%' }} /></Form.Group>
                            </Col>
                        </Row>
                        <Button variant="primary btn-sm mt-2" type="submit">Submit</Button>
                        <Button variant="secondary btn-sm mt-2" onClick={() => setShowModal(false)} style={{ marginLeft: '10px' }}>Close</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal for New BulkUpload */}
            <Modal show={showBulkUploadModal} onHide={() => setShowBulkUploadModal(false)}>
                <Modal.Header closeButton><Modal.Title>BULK UPLOAD LEADS</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <div><p>Select Excel File</p><Form.Control type="file" accept=".xlsx, .xls" onChange={handleFileChange} /></div>
                        <Button variant="primary" className="btn-sm mt-2" onClick={handleBulkUpload} >Upload</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal for New intersted */}
            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered size="xl">
                <Modal.Header closeButton><Modal.Title>Next Action</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleinterested}>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Select Customer</Form.Label>
                                    <Form.Control as="select" value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.target.value)} style={{ border: '1px solid black', padding: '10px', width: '100%' }} >
                                        <option value="">Select...</option>
                                        {leads.filter(lead => lead.customername !== selectedCustomer).map((lead) => (
                                            <option key={lead.id} value={lead.customername}>{lead.customername}</option>))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formName"><Form.Label>Name</Form.Label><input type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={selectedLead ? selectedLead.customername : ''} readOnly /></Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formMobile"><Form.Label>Mobile</Form.Label><input type="tel" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={selectedLead ? selectedLead.mobile : ''} readOnly /></Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formEmail"><Form.Label>Email</Form.Label><input type="email" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={selectedLead ? selectedLead.email : ''} readOnly /></Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="formAltMobile"><Form.Label>Alternate Mobile</Form.Label><input type="tel" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={alternateMobile} onChange={(e) => setAlternateMobile(e.target.value)} /></Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formAadharNumber"><Form.Label>Aadhar Number</Form.Label><input type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={aadharNumber} onChange={(e) => setAadharNumber(e.target.value)} /></Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formPanNumber"><Form.Label>Pan Number</Form.Label><input type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={panNumber} onChange={(e) => setPanNumber(e.target.value)} /></Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="formPermanentAddress"><Form.Label>Permanent Address</Form.Label><textarea type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} rows={3} value={permanentAddress} onChange={(e) => setPermanentAddress(e.target.value)} /></Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formCurrentAddress"><Form.Label>Current Address</Form.Label><textarea type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} rows={3} value={currentAddress} onChange={(e) => setCurrentAddress(e.target.value)} /></Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group controlId="formComment"><Form.Label>Comment</Form.Label><textarea type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={comment} onChange={(e) => setComment(e.target.value)} rows={3} required /></Form.Group>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-between mt-3">
                            <Button variant="primary" type="submit" size="sm">Submit</Button>
                            <Button variant="danger" size="sm" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal >

            {/* Modal for New Callback */}
            < Modal show={showCallbackModal} onHide={() => setShowCallbackModal(false)} size="lg" >
                <Modal.Header closeButton><h6>Set Callback Date</h6></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleCallbackSubmit}>
                        <Form.Group controlId="formCallbackDate">
                            <Form.Label>Callback Date</Form.Label>
                            <input type="date" value={callbackDate} onChange={(e) => setCallbackDate(e.target.value)} required min={new Date().toISOString().split("T")[0]} style={{ border: '1px solid black', padding: '10px', width: '100%' }} />
                        </Form.Group>
                        <Button variant="primary btn-sm" type="submit" className="mt-2">Submit</Button>
                        <Button variant="secondary btn-sm" onClick={() => setShowCallbackModal(false)} className="mt-2" style={{ marginLeft: '10px' }}>Close</Button>
                    </Form>
                </Modal.Body>
            </Modal >

            {/* Modal for New NotInterested */}
            {showNotInterestedModal && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', border: '1px solid #ccc', padding: '40px', boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', zIndex: 1001, width: '500px', borderRadius: '8px' }}>
                    <h3 style={{ marginBottom: '20px', textAlign: 'center' }}>Reason for Not Interested</h3>
                    <form onSubmit={handleNotInterestedSubmit}>
                        <label style={{ display: 'block', marginBottom: '10px' }}>Reason:
                            <textarea value={notInterestedReason} onChange={(e) => setNotInterestedReason(e.target.value)} required style={{ border: '1px solid black', padding: '10px', width: '100%' }} />
                        </label>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <button type="submit" className='btn btn-primary btn-sm'>Submit</button>
                            <button type="button" onClick={() => setShowNotInterestedModal(false)} className='btn btn-danger btn-sm'>Cancel</button>
                        </div>
                    </form>
                </div>
            )}

        </>
    );
}
