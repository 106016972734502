import React, { useState } from 'react'
import { BsPersonCircle, BsJustify, BsSearch, BsBoxArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';

function Header({ OpenSidebar }) {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('companyLogo');
        sessionStorage.removeItem('emailUser');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userType'); 
        navigate('/');
        console.log('Logging out...');
        setDropdownVisible(false);
    };

    return (
        <div className='header'>
            <div className='menu-icon'>
                <BsJustify className='icon' onClick={OpenSidebar} />
            </div>
            <div className='header-left'>
                <BsSearch className='icon' style={{ color: '#b7c7d2' }} />
            </div>
            
            <div>
                <BsPersonCircle size={25}
                    style={{ cursor: 'pointer' }}
                    onClick={toggleDropdown}
                />
                {dropdownVisible && (
                    <div style={{ position: 'absolute', right: '10px', bottom: '85%', backgroundColor: '#fff', zIndex: 1000, borderRadius: '20px' }}>
                        <button onClick={handleLogout} className='dropdown-items'>
                            <BsBoxArrowRight style={{ marginRight: '5px' }} />
                            Logout
                        </button>

                        
                    </div>
                )}
            </div>

        </div>
    )
}

export default Header