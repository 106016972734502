import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure you have Bootstrap CSS loaded
import { AiOutlineKey, AiOutlineMail } from 'react-icons/ai';
import ReCAPTCHA from 'react-google-recaptcha';



const Emailverification = () => {
  const [email, setEmail] = useState('');
  const [otp, setOTP] = useState('');
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://cloudconnectapi.emedha.in/auth/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp }),
      });

      const data = await response.json();

      if (response.ok) {
        setVerified(true);
        setError('');
        alert('Email verified successfully!');
        navigate('/');
      } else {
        setError(data.error || 'Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      setError('Verification failed. Please try again.');
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center align-items-center min-vh-60 ">

        <div className=" align-items-center p-5 ">
          <h1 className='text-center mt-5' style={{ fontSize: '50px' }}><strong>Email Verification</strong></h1>

          {!verified ? (
            <form onSubmit={handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}

              <div className='mt-3' style={{ position: 'relative' }}>
                <AiOutlineMail style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
                <input type="email" placeholder="Enter your email" style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} value={email} onChange={handleEmailChange} required />
              </div>

              <div className="mt-3" controlId="otp" style={{ position: 'relative' }}>
                <AiOutlineKey style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
                <input type="text" placeholder="Enter OTP" value={otp} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} onChange={handleOTPChange} required />
              </div>

              <ReCAPTCHA className='mt-3' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />

              <button className='mt-3' style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%', backgroundColor: '#07526f', color: 'white' }} type="submit">
                Verify
              </button>
            </form>
          ) : (
            <Alert variant="success">Email verified!</Alert>
          )}

        </div>

      </div>
    </>
  );
};

export default Emailverification;
