import React, { useState } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';



const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [error, setError] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    try {
      const response = await fetch('https://cloudconnectapi.emedha.in/auth/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setResetToken(data.resetToken); // Assuming your backend returns resetToken upon successful request
        setResetPasswordMode(true);
      } else {
        setError(data.error || 'Failed to send password reset email');
      }
    } catch (error) {
      setError('Failed to send password reset email');
    }
  };

  return (
    <>
      {/* <div className="mt-5 d-flex justify-content-center align-items-center">
        <Row className="mt-5 justify-content-center">
          <Col className="mt-5 p-4 p-md-5 rounded bg-light">
            <div className="d-flex align-items-center mb-3">
              <h1 className="mb-0" style={{ fontSize: '50px' }}><strong>Reset Your Password</strong></h1>
            </div>

            <Form onSubmit={handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}
              {resetPasswordMode && <Alert variant="success">Check your email for a password reset link.</Alert>}

              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <div className='mb-3'>
                  <input type='email' placeholder='Enter email' style={{ border: '1px solid black', padding: '10px 120px' }} value={email} onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <ReCAPTCHA className='mb-3' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />

              </Form.Group>
              <button type="submit" style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%', backgroundColor: '#07526f', color: 'white' }}>
                Reset Password
              </button>
            </Form>
          </Col>
        </Row>
      </div> */}
      <div className="mt-5 d-flex justify-content-center align-items-center">
        <Row className="mt-5 justify-content-center">
          <Col className="mt-5 p-4 p-md-5 rounded bg-light">
            <div className="d-flex align-items-center mb-3">
              <h1 className="mb-0" style={{ fontSize: '50px' }}><strong>Reset Your Password</strong></h1>
            </div>

            <Form onSubmit={handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}
              {resetPasswordMode && <Alert variant="success">Check your email for a password reset link.</Alert>}

              <Form.Group controlId="email">
                <Form.Label>Email address</Form.Label>
                <div className='mb-3'>
                  <input
                    type='email'
                    placeholder='Enter email'
                    style={{ border: '1px solid black', padding: '10px 120px' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <ReCAPTCHA className='mb-3' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />
              </Form.Group>

              <button
                type="submit"
                style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%', backgroundColor: '#07526f', color: 'white' }}>
                Reset Password
              </button>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ForgotPassword;
