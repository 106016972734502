import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/sidebar';
import Header from './Header';

const Dashboard = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [leads, setLeads] = useState([]);
    const [totalLeads, setTotalLeads] = useState(0);
    const [totalCallbacks, setTotalCallbacks] = useState(0);
    const [showNotification, setShowNotification] = useState(false);
    const [callbackData, setCallbackData] = useState([]); // New state for callback data
    const [showCallbackData, setShowCallbackData] = useState(false); // Control display of callback data

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    // Fetch leads data when component mounts
    useEffect(() => {
        const fetchLeads = async () => {
            const userId = JSON.parse(sessionStorage.getItem('userData')).id;
            try {
                const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/leads?userId=${encodeURIComponent(userId)}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setLeads(data);
                setTotalLeads(data.length);
            } catch (error) {
                console.error('Error fetching leads:', error.message);
            }
        };

        const fetchCallbacks = async () => {
            const userId = JSON.parse(sessionStorage.getItem('userData')).id;
            try {
                const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/callbacks/today?userId=${encodeURIComponent(userId)}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setTotalCallbacks(data.length);
                if (data.length > 0) {
                    setShowNotification(true);
                }
            } catch (error) {
                console.error('Error fetching callbacks:', error.message);
            }
        };

        fetchLeads();
        fetchCallbacks();
    }, []);

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    const handleViewCallbacks = async () => {
        const userId = JSON.parse(sessionStorage.getItem('userData')).id;
        try {
            const response = await fetch(`https://cloudconnectapi.emedha.in/pages/api/api/callbacks/today?userId=${encodeURIComponent(userId)}`); // Adjust URL if necessary
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setCallbackData(data);
            setShowCallbackData(true); // Show callback data
        } catch (error) {
            console.error('Error fetching callback data:', error.message);
        }
    };

    return (
        <>
            <div className='grid-container'>
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

                <div className='main-container' style={{ backgroundColor: '#e2eef1', padding: '20px' }}>
                    <div className='mt-2'>
                        <h5>WELCOME TO DASHBOARD</h5>
                    </div>

                    {showNotification && (
                        <div className="notification" style={{ margin: '20px 0', padding: '10px', backgroundColor: '#fffae6', border: '1px solid #ffd700', borderRadius: '5px' }}>
                            <p>You have {totalCallbacks} callback request(s) for today!</p>
                            <button onClick={handleCloseNotification} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>Close</button>
                            <button onClick={handleViewCallbacks} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>View</button>
                        </div>
                    )}

                    <div className='mt-5' style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
                        <div style={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '20px', width: '150px', textAlign: 'center' }}>
                            <h6>Total Customers</h6>
                            <p>{totalLeads}</p>
                        </div>
                        <div style={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '20px', width: '150px', textAlign: 'center' }}>
                            <h6>Total Leads</h6>
                            <p>{totalLeads}</p>
                        </div>
                        <div style={{ backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '20px', width: '150px', textAlign: 'center' }}>
                            <h6>CallBack Requests</h6>
                            <p>{totalCallbacks}</p>
                        </div>
                    </div>

                    {/* Display callback data if available */}
                    {showCallbackData && (
                        <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#f9f9f9', border: '1px solid #ddd', borderRadius: '5px' }}>
                            <h6>Callback Requests</h6>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Customer Name</th>
                                        <th>Location</th>
                                        <th>Requirement</th>
                                        <th>Email</th>
                                        <th>Mobile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {callbackData.map((callback) => (
                                        <tr key={callback.id}>
                                            <td>{callback.customername}</td>
                                            <td>{callback.location}</td>
                                            <td>{callback.requirement}</td>
                                            <td>{callback.email}</td>
                                            <td>{callback.mobile}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button onClick={() => setShowCallbackData(false)} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>Close</button>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
};

export default Dashboard;
