import axios from 'axios';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { AiOutlineFile, AiOutlineLock, AiOutlineMail, AiOutlinePhone, AiOutlineUser } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import A1 from '../Images/elo.png';

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    mobile: '',
    email: '',
    password: '',
    confirmPassword: '',
    company_name: '',
    company_logo: null, // Store the file as null initially
  });

  const Navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Handle change for both regular form fields and file input
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'company_logo') {
      // Handle file input
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { username, email, password, confirmPassword, mobile, company_logo, company_name } = formData;

    // Simple validation for matching passwords
    if (password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }

    try {
      // Create FormData object to send both text fields and file
      const formDataToSend = new FormData();

      formDataToSend.append('username', username);
      formDataToSend.append('mobile', mobile);
      formDataToSend.append('email', email);
      formDataToSend.append('password', password);
      formDataToSend.append('company_name', company_name);
      if (company_logo) {
        formDataToSend.append('company_logo', company_logo);
      }

      // Make POST request to backend endpoint
      const response = await axios.post('https://cloudconnectapi.emedha.in/auth/api/Signup', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });

      // Check response status and handle accordingly
      if (response.status === 200) {
        alert(response.data.message); // Backend success message
        Navigate('/verification');
        // Reset form data
        setFormData({
          username: '',
          mobile: '',
          email: '',
          password: '',
          confirmPassword: '',
          company_logo: null,
          company_name: '',
        });
      } else {
        alert('Failed to register user. Please try again.');
      }
    } catch (error) {
      // Handle error scenarios
      if (error.response) {
        console.error('Response error:', error.response);
        alert(error.response.data.error);
      } else if (error.request) {
        console.error('Request error:', error.request);
        alert('Failed to send request to server.');
      } else {
        console.error('Error:', error.message);
        alert('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
      <img src={A1} alt='Logo' style={{ height: '40px' }} />
      <h1 className='text-center mt-2' style={{ fontSize: '50px' }}><strong>Create an account</strong></h1>

      {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

      <form className='mt-3' onSubmit={handleSubmit} style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
        <div style={{ flex: '1 1 45%', position: 'relative' }}>
          <AiOutlineUser style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
          <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} required />
        </div>

        <div style={{ flex: '1 1 45%', position: 'relative' }}>
          <AiOutlinePhone style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
          <input type="number" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleChange} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} required />
        </div>

        <div style={{ flex: '1 1 45%', position: 'relative' }}>
          <AiOutlineMail style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
          <input type="email" name="email" placeholder='Email' value={formData.email} onChange={handleChange} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} required />
        </div>

        <div style={{ flex: '1 1 45%', position: 'relative' }}>
          <AiOutlineLock style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
          <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} required />
        </div>

        <div style={{ flex: '1 1 45%', position: 'relative' }}>
          <AiOutlineLock style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
          <input type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} required />
        </div>

        <div style={{ flex: '1 1 45%', position: 'relative' }}>
          <AiOutlineUser style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
          <input type="text" name="company_name" placeholder='Company Name' value={formData.company_name} onChange={handleChange} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} required />
        </div>

        <div style={{ flex: '1 1 45%', position: 'relative' }}>
          <AiOutlineFile style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }} />
          <input type="file" name="company_logo" onChange={handleChange} style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%' }} required />
        </div>

        <ReCAPTCHA className='mt-2' sitekey="YOUR_RECAPTCHA_SITE_KEY" />

        <div className='mt-2' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flex: '1 1 100%' }}>
          <button type="submit" className='btn btn-sm' style={{ border: '1px solid black', padding: '10px 10px 10px 40px', width: '100%', backgroundColor: '#07526f', color: 'white' }}>
            Sign Up
          </button>
        </div>
      </form>

      <div className='mt-2' style={{ textAlign: 'center' }}>
        <hr style={{ width: '100px', display: 'inline-block' }} />
        <span>or</span>
        <hr style={{ width: '100px', display: 'inline-block' }} />
      </div>

      <div className='mt-2'>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <span style={{ color: 'black' }}><strong>Already have an account?</strong></span>
          <span> <AiOutlineUser /> Login</span>
        </Link>
      </div>
    </div>
  );
};

export default Signup;
