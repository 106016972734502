import React, { useState } from 'react';
import A1 from '../Images/elo.png';
import { FaTachometerAlt, FaUserFriends, FaUsers } from 'react-icons/fa';

function Sidebar({ openSidebarToggle, OpenSidebar }) {

    const company_logo = sessionStorage.getItem("companyLogo");

    const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);

    const toggleCustomerDropdown = () => {
        setIsCustomerDropdownOpen(!isCustomerDropdownOpen);
    };

    return (
        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
            <div className='sidebar-title'>
                <div className='sidebar-brand'>
                    <img src={company_logo} alt='nir' style={{ height: '40px' }} />
                </div>
                <span className='icon close_icon' onClick={OpenSidebar}>X</span>
            </div>
            <ul className='sidebar-list'>
                <li className='sidebar-list-item'><a href="/dashboard"><FaTachometerAlt className='icon' /> Dashboard</a></li>
                <li className='sidebar-list-item'><a href="/primary/leadmanagement/*&gfd$/jhf&"><FaUserFriends className='icon' /> Lead Management</a></li>
                <li className='sidebar-list-item' onClick={toggleCustomerDropdown}>
                    <FaUsers className='icon' /> Customer Management
                    {isCustomerDropdownOpen && (
                        <ul style={{ paddingLeft: '20px', margin: 0 }}>
                            <li style={{ padding: '8px 0' }}><a href="/crm/CustomerManagement/&g$ghf/*hg" style={{ textDecoration: 'none' }}>Sales</a></li>
                            <li style={{ padding: '8px 0' }}><a href="#" style={{ textDecoration: 'none' }}>Billing</a></li>
                            <li style={{ padding: '8px 0' }}><a href="#" style={{ textDecoration: 'none' }}>Payment</a></li>
                        </ul>
                    )}
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;
