import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Dashboard from './Components/Pages/Dashboard';
import Primary from './Components/Pages/Primary';
import Crm from './Components/Pages/crm';
import Login from './Components/Auth/login';
import Signup from './Components/Auth/Signup';
import Emailverification from './Components/Auth/emailverification';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';


function App() {


  return (
    <>
      <Router>
        <Routes>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/primary/leadmanagement/*&gfd$/jhf&' element={<Primary />} />
          <Route path='/crm/CustomerManagement/&g$ghf/*hg' element={<Crm />} />
          <Route path='/' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/verification' element={<Emailverification />} />
          <Route path='/forgot' element={<ForgotPassword />} />
          <Route path='/reset-password/:resetToken' element={<ResetPassword />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
